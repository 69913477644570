<template>
  <div>
    <ed-profissional  :intProfissionalId="$route.params.intId" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdProfissional from "@/components/cadastro/profissional/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdProfissional },
  mounted() {
    if(!this.$route.params.intId){
      this.$router.push({ name: "cadastro.profissional.register" });
    }
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>